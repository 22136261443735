import api_request from "../../../libs/sendRequest/sendRequest";
import magicJson from "./../../../magicJson";

class ContentTypeBuilder {
  api_request = new api_request("/api/service");

  async getCollectionItems(_id) {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "getCollectionItems";
      magicJson.data = { _id };
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async updatecollectionOrder(data) {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "updatesortedCollection";
      magicJson.data = { data };
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async createCollectionItem(data, imagesData) {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "createCollectionItem";
      magicJson.data = data;
      const result = await this.api_request.sendRequest(magicJson, imagesData);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async updateCollectionItem(data, imageData) {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "updateCollectionItem";
      magicJson.data = data;
      const result = await this.api_request.sendRequest(magicJson, imageData);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async deleteCollectionItem(id) {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "deleteCollectionItem";
      magicJson.data = [{ id }];
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async searchLocation(searchValue) {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "searchLocation";
      magicJson.data = [{ searchValue }];
      const result = await this.api_request.sendRequest(magicJson);
      return result;
  }

  tagsGenerator = async (data) => {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "generateTags";
      magicJson.data = [data];
      const result = await this.api_request.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  };

  async getDraftData(id) {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "getDraftData";
      magicJson.data = [{ id }];
      const result = await this.api_request.sendRequest(magicJson);
      return result;
    } catch (error) {
      return error;
    }
  }

  async draftPage(id, data, images) {
    try {
      console.log(images)
      magicJson.endpoint = "contentManager";
      magicJson.executor = "draftPage";
      magicJson.data = data;
      magicJson.metadata.data.filter = { pageid: id };
      const result = await this.api_request.sendRequest(magicJson, images);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async getDraftList(parentId, status) {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "getDraftList";
      magicJson.data = { parentId, status };
      const result = await this.api_request.sendRequest(magicJson);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async draftsave(id, data, images) {
    try {
      console.log(images)
      magicJson.endpoint = "contentTypeBuilder";
      magicJson.executor = "draftPage";
      magicJson.data = data;
      magicJson.metadata.data.filter = { pageid: id };
      const result = await this.api_request.sendRequest(magicJson, images);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

  async updateDraft(data, files) {
    try {
      magicJson.endpoint = "contentManager";
      magicJson.executor = "updateDraft";
      magicJson.data = data;
      const result = await this.api_request.sendRequest(magicJson, files);
      return result.data[0];
    } catch (error) {
      return error;
    }
  }

}

export default ContentTypeBuilder;
