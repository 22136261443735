export const GlobalMixin = {
  methods: {
    capitalizeFirstLetter(str) {
      if (typeof str !== "string" || str.length === 0) return str;
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    lowercaseFirstLetter(str) {
      return typeof str === "string" && str.length > 0
        ? str.charAt(0).toLowerCase() + str.slice(1)
        : str;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      const year = date.getFullYear(); // Get full year (e.g., 2024)
      return `${month}-${day}-${year}`;
    },
    validatePassword(password) {
      const regex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (password === "") {
        return true;
      }
      if (!regex.test(password)) {
        return false;
      }
      return true;
    },
    validateUsername(username) {
      if (username === "") {
        return true;
      }
      const regex = /^[A-Za-z]+$/;
      return regex.test(username);
    },
  },
};
